<script setup lang="ts">
const { rma } = useAppConfig()
const localePath = useLocalePath()
const locale = useI18n().locale
const switchLocalePath = useSwitchLocalePath()

const userDropdown = ref()

async function logout (e: Event) {
  await useLogout(e)
  await navigateTo(localePath('/'), {})
  // refresh
  window.location.reload()
}
</script>

<template>
  <header
    class="fixed backdrop-filter backdrop-blur-md top-0 z-40 w-pare w-[inherit] transition-colors duration-300 lg:z-50 py-2"
  >
    <div class="grid grid-cols-2 md:grid-cols-2 bg-base-100">
      <div class="justify-self-start">
        <NuxtLink
          :to="localePath('/')"
          class="btn btn-ghost normal-case text-xl"
        >
          {{ rma.name }}
        </NuxtLink>
      </div>
      <div class="justify-self-end flex">
        <div class="dropdown dropdown-bottom">
          <div
            role="button"
            tabindex="0"
            class="btn btn-ghost btn-md btn-warning"
          >
            <Icon
              :name="locale === 'de' ? 'emojione-v1:flag-for-germany' : 'emojione-v1:flag-for-united-kingdom'"
              style="width: 1.5rem; height: 1.5rem"
            />
          </div>
          <ul
            tabindex="0"
            class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-content"
          >
            <li>
              <NuxtLink :to="switchLocalePath('de')">
                <Icon name="emojione-v1:flag-for-germany" />German
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="switchLocalePath('en')">
                <Icon
                  name="emojione-v1:flag-for-united-kingdom"
                />English
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div
          ref="userDropdown"
          class="dropdown"
          @click="useUser().value ? null : $router.push('/login')"
        >
          <div role="button" class="btn btn-ghost" tabindex="1">
            <div v-if="useUser().value?.username">
              {{ useUser().value.username }}
            </div>
            <Icon
              name="fluent:person-16-filled"
              style="width: 1.5rem; height: 1.5rem"
            />
          </div>
          <ul
            v-if="useUser().value"
            tabindex="1"
            class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-fit"
          >
            <li v-if="useUser().value?.permissions.includes('dashboard')">
              <NuxtLink :to="localePath('/admin')">
                {{
                  $t('admin.dropdown.dashboard')
                }}
              </NuxtLink>
            </li>

            <li @click="logout">
              <a class="red dark:text-red-600">{{
                $t('admin.dropdown.logout')
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped></style>
