export default async function (e: Event): Promise<number | string> {
  try {
    await $fetch('/api/logout', {
      method: 'POST'
    }).catch((err) => {
      console.log('Error in useLogout.ts: ', e)
      return err.response
    })
  } catch (e) {
    if (e instanceof Error) { return e.message }
  }

  return 200
}
