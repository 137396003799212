<script setup lang="ts">
const { rma } = useAppConfig()
const localePath = useLocalePath()
</script>

<template>
  <footer class="flex border-t border-gray-900/10 dark:border-gray-50/[0.2] w-full">
    <div
      class="grid grid-cols-3 justify-items-center w-full h-full px-4 text-sm text-gray-500 dark:text-gray-400 items-center"
    >
      <div class="justify-self-start">
        Copyright ©
        {{ rma?.layout?.footer?.year || new Date().getFullYear() }}
        {{ rma?.author?.name || '' }}. All rights reserved.
      </div>
      <div class="cursor-pointer" @click="navigateTo(localePath('/privacy'))">
        {{ $t('layout.privacy') }}
      </div>
      <div class="justify-self-end cursor-pointer" @click="navigateTo(localePath('/legal'))">
        {{ $t('layout.legal') }}
      </div>
    </div>
  </footer>
</template>
